<template>
  <div>
    <div class="card-toolbar mb-5 d-flex justify-content-between">
      <div>
        <router-link v-if="$can('suppliers.create')"
                     to="/purchases/suppliers/create"
                     class="btn btn-primary font-weight-bolder"
        >
          <v-icon>mdi-plus</v-icon>
          {{ $t('suppliers.add_supplier') }}
        </router-link
        >
        <button @click="openAdvancedSearch" class="btn btn-outline-primary font-weight-bolder ml-5">
          <span><i class="la la-filter"></i> <span>{{ $t('advanced_filter') }}</span></span>
        </button>
      </div>
      <div>
        <router-link v-if="$can('suppliers.export_import_suppliers')" to="/suppliers/export-import-suppliers" class="btn btn-primary font-weight-bolder h-100">
          {{ $t('MENU.export_import_suppliers') }}
        </router-link>
      </div>
    </div>

    <div class="card card-custom mb-5" v-if="showAdvancedSearch">
      <div class="card-body">
        <div class="m-form m-form--fit m--margin-bottom-20">
          <div class="row mb-5">
            <div class="form-group col-md-4">
              <label for="name">{{ $t('suppliers.name') }}</label>
              <input v-model="filters.name" type="text" id="name" class="form-control">
            </div>
            <div class="form-group col-md-4">
              <label for="supplier_code">{{ $t('suppliers.supplier_code') }}</label>
              <input v-model="filters.supplier_code" type="text" id="supplier_code" class="form-control">
            </div>
            <div class="form-group col-md-4">
              <label for="email">{{ $t('suppliers.email') }}</label>
              <input v-model="filters.email" type="text" id="email" class="form-control">
            </div>
            <div class="form-group col-md-4">
              <label for="mobile">{{ $t('suppliers.mobile') }}</label>
              <input v-model="filters.mobile" type="text" id="mobile" class="form-control">
            </div>
            <div class="form-group col-md-4">
              <label for="phone">{{ $t('suppliers.phone') }}</label>
              <input v-model="filters.phone" type="text" id="phone" class="form-control">
            </div>

            <div class="form-group col-md-4">
              <label>{{ $t('customers.country') }}</label>
              <multiselect
                  v-model="country"
                  :placeholder="$t('customers.country')"
                  label="name"
                  track-by="code2"
                  :options="countries"
                  :multiple="false"
                  :taggable="false"
                  :show-labels="false"
                  :show-no-options="false"
                  :show-no-results="false"
                  @search-change="getCountries($event)"
                  :internal-search="false">
              </multiselect>
            </div>
            <div class="form-group col-md-4">
              <label for="postal_code">{{ $t('suppliers.postal_code') }}</label>
              <input v-model="filters.postal_code" type="text" id="postal_code" class="form-control">
            </div>
            <div class="form-group col-md-4">
              <label for="postal_code">{{ $t('suppliers.address_1') }}</label>
              <input v-model="filters.address_1" type="text" id="address_1" class="form-control">
            </div>
            <div class="form-group col-md-4">
              <label>{{ $t('added_by') }}</label>
              <multiselect
                  v-model="user"
                  :placeholder="$t('added_by')"
                  label="name"
                  track-by="id"
                  :options="users"
                  :multiple="false"
                  :taggable="false"
                  :show-labels="false"
                  :show-no-options="false"
                  :show-no-results="false"
                  @search-change="getUsers($event)">
              </multiselect>
            </div>
            <div class="form-group col-md-6 mt-2 mb-2">
              <label for="f_status">{{ $t('status') }}</label>
              <select name="" id="f_status" v-model="filters.status" type="text" class="custom-select">
                <option value="">{{ $t('all') }}</option>
                <option v-for="(row, index) in status_list" :value="row.id" :key="index">{{ row.text }}</option>
              </select>
            </div>

            <div class="form-group d-inline-flex col-md-6 mt-10">
              <button type="button" @click="doFilter" class="btn btn-primary mt-auto mr-2" id="m_search">
                              <span><i class="fas fa-search"></i>
                                  <span>{{ $t('search') }}</span>
                              </span>
              </button>
              <button type="button" @click="resetFilter" class="btn btn-danger mt-auto mr-2" id="m_reset">
                              <span><i class="fas fa-trash-restore"></i>
                                  <span>{{ $t('reset_search') }}</span>
                              </span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card card-custom">
      <div class="card-body">
        <div>
          <div class="row justify-content-end p-4">

            <custom-export-data v-if="$can('suppliers.export_all_suppliers')"
                                :route="mainRoute"
                                :action-name="$t('export_all')"
                                :filters="{type:'export', limit: dataLimitedForExport()}"
                                :fields="json_fields"
                                :file-name="$t('MENU.suppliers_management')" :id-name="'sectionForExportAll'" :ignore-actions="['print','pdf']"></custom-export-data>
          </div>
        </div>

        <v-server-table :class="'dataTable table-row-dashed'" :columns="columns" :options="options" ref="table">
          <template slot="#" slot-scope="props">
            <span>{{props.index}}</span>
          </template>
          <template slot="status" slot-scope="props">
            <b-form-checkbox v-if="$can('suppliers.change_status')"
                             size="lg" @change="changeStatus(props.row.id, props.row.is_active)"
                             v-model="props.row.is_active"
                             :name="'check-button'+props.row.id"
                             switch :key="props.row.id">
            </b-form-checkbox>
            <b-form-checkbox v-else
                             size="lg" :disabled="true"
                             v-model="props.row.is_active"
                             :name="'check-button'+props.row.id"
                             switch :key="props.row.id">
            </b-form-checkbox>
          </template>

          <template slot="actions" slot-scope="props">
            <v-btn v-b-tooltip.hover :title="$t('edit')" v-if="$can('suppliers.update')" icon color="pink" :to="`/purchases/suppliers/edit/${props.row.id}`">
              <v-icon small class="mr-2 text-info">mdi-pencil</v-icon>
            </v-btn>
            <v-icon small class="text-danger" v-b-tooltip.hover :title="$t('delete')" v-if="$can('suppliers.delete')" @click="deleteItem(props.row)">mdi-delete</v-icon>
            <v-btn icon color="pink" :title="$t('view')" v-if="$can('suppliers.list')" :to="`/purchases/suppliers/view/${props.row.id}`">
              <v-icon small class="mr-2">mdi-eye</v-icon>
            </v-btn>
            <b-dropdown :id="'dropdown-offset_'+props.row.id" :text="$t('more_actions') " variant="outline-primary" class="m-2">
              <button class="dropdown-item" v-if="$can('suppliers.open_balance')" @click="addOpenBalance(props.row.id)" :key="props.row.id">
                {{ $t('suppliers.open_balance') }}
              </button>
              <button v-if="$can('suppliers.account_statement')" class="dropdown-item" @click="showAccountStatement(props.row.id)" :key="'supplier-account-statement'+props.row.id">
                {{ $t('account_statement') }}
              </button>
              <button v-if="isSystemUser" class="dropdown-item" @click="retractFromTheOpeningBalance(props.row.id)">
                {{ $t('back_off_the_opening_balance') }}
              </button>
<!--              <router-link target="_blank" class="dropdown-item" :to="`/print/supplier-account-statement/${props.row.id}`">-->
<!--                {{ $t('account_statement') }}-->
<!--              </router-link>-->
            </b-dropdown>
          </template>
        </v-server-table>

      </div>
    </div>

    <b-modal ref="open-balance-modal" size="xl" hide-footer :title="$t('suppliers.open_balance')">
      <supplier-open-balance-form @hide-modal="hideModal()"
                                  :current-id="innerId"
                                  @handling-data="getDataAfterSave">
      </supplier-open-balance-form>
    </b-modal>

    <b-modal ref="account-statement-modal" size="xl" hide-footer :title="$t('account_statement')">
      <div>
        <div class="form-group row">
          <div class="col-lg-6 mb-5">
            <label>{{ $t('from_date') }}</label>
            <input type="date" v-model="account_statement.from_date" class="form-control"/>
          </div>
          <div class="col-lg-6 mb-5">
            <label>{{ $t('to_date') }}</label>
            <input type="date" v-model="account_statement.to_date" class="form-control"/>
          </div>
        </div>
        <div class="pl-0 pr-0 pb-0">
          <div class="row">
            <div class="col-lg-6">
              <button type="reset" class="btn btn-primary mr-2" @click="getAccountStatement">{{ $t('search') }}</button>
              <button type="reset" class="btn btn-secondary" @click="hideAccountStatementModal">{{ $t('cancel') }}</button>
            </div>
          </div>
        </div>
      </div>

    </b-modal>
  </div>
</template>

<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";
import SupplierOpenBalanceForm from "@/view/content/forms/SupplierOpenBalanceForm.vue";
import {mapGetters} from "vuex";
import dataLimited from "@/core/config/mix/dataLimited";

export default {
  name: "index-customers",
  components: {SupplierOpenBalanceForm},
  data() {
    return {
      mainRoute: 'purchases/suppliers',
      routeChangeStatus: 'purchases/supplier/change-status',

      showAdvancedSearch: false,

      filter: {
        sortBy: 'id',
      },
      filters: {
        name: null,
        supplier_code: null,
        email: null,
        mobile: null,
        phone: null,
        country_code: null,
        postal_code: null,
        address_1: null,
        user_id: null,
        status: null,
      },
      status_list: [
        {id: 1, text: this.$t('active')},
        {id: 0, text: this.$t('inactive')},
      ],
      columns: ['#','business_name', 'full_name', 'supplier_code', 'country_name', 'city_name', 'status', 'actions'],
      data: [],
      countries: [],
      country: null,
      users: [],
      user: null,

      innerId: null,
      supplierOpenBalanceId: null,
      account_statement:{
        from_date: null,
        to_date: null,
      },

      dataList: [],
    }
  },
  watch: {
    country: function (val) {
      if (val) {
        this.filters.country_code = val.code2;
      } else {
        this.filters.country_code = '';
      }
    },
    user: function (val) {
      if (val) {
        this.filters.user_id = val.id;
      } else {
        this.filters.user_id = null;
      }
    },
    "filters.mobile" : function (val) {
      if (val) {
        this.filters.mobile = val.replace(/\+/g, '');
      }
    },
    "filters.phone" : function (val) {
      if (val) {
        this.filters.phone = val.replace(/\+/g, '');
      }
    }
  },
  computed: {
    ...mapGetters(["currentUserPersonalInfo"]),
    isSystemUser: function () {
      return this.currentUserPersonalInfo.is_system_user
    },
    json_fields: function () {
      let fields = {};
      fields[this.$t('suppliers.full_name')] = 'full_name';
      fields[this.$t('suppliers.business_name')] = 'business_name';
      fields[this.$t('suppliers.supplier_code')] = 'supplier_code';
      fields[this.$t('suppliers.country')] = 'country_name';
      fields[this.$t('suppliers.city')] = 'city_name';

      return fields;
    },
    options: function () {
      let that = this;
      return {
        texts: {
          loadingError: that.$t('Something_went_wrong'),
          filter: "",
          limit: that.$t('records'),
          filterBy: that.$t('Filter') + ' {column}',
          count: ' ',
          filterPlaceholder: that.$t('Search_query'),
          loading: that.$t('Loading') + "...",
        },
        headings: {
          full_name: that.$t('suppliers.full_name'),
          business_name: that.$t('suppliers.business_name'),
          supplier_code: that.$t('suppliers.supplier_code'),
          country_name: that.$t('suppliers.country'),
          city_name: that.$t('suppliers.city'),
          status: that.$t('status'),
          actions: that.$t('actions'),
        },
        sortable: ['name'],
        filterByColumn: false,
        filterable: false,
        customFilters: [{
          name: 'alphabet',
        }],
        orderBy: {'column': 'id'},

        alwaysShowPerPageSelect: false,
        perPage: 10,
        pagination: {chunk: 5, dropdown: false},
        skin: 'table align-middle table-row-dashed fs-5 gy-5 text-center dataTable dtr-inline',
        sortIcon: {base: 'fas ml-2', up: 'fa-sort-alpha-up', down: 'fa-sort-alpha-down', is: 'fa-sort'},
        perPageValues: [5, 10, 15, 25],

        requestFunction(data) {
          let _params = {
            ascending: data.ascending,
            byColumn: data.byColumn,
            limit: data.perPage,
            orderBy: data.orderBy,
            page: data.page,
            filter: data.query,
            ...that.filters,
          }
          return ApiService.query(`${that.mainRoute}`, {..._params});

        },
        responseAdapter(resp) {
          that.dataList = resp.data.data.data;
          return {
            data: resp.data.data.data,
            count: resp.data.data.total,
          }
        }
      }

    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t("MENU.suppliers_management")}]);
    // this.getCountries();
    // this.getUsers();
  },
  methods: {
    ...dataLimited,
    retractFromTheOpeningBalance(id) {
      this.$confirmAlert(this.$t('are_you_sure_that_you_want_to_retract_from_the_opening_balance'), this.retractFromTheOpeningBalanceItem, id);
    },
    retractFromTheOpeningBalanceItem(id) {
      if (id)
        ApiService.get(`${this.mainRoute}/${id}/open_balance_rollback`).then((response) => {
          this.$successAlert(response.data.message)
        }).catch((error) => {
          this.$errorAlert(error)
        })
    },
    changeStatus(id, status) {
      ApiService.patch(this.routeChangeStatus + '/' + id, {
        is_active: (status ? 1 : 0),
      }).then((response) => {
        this.$refs.table.refresh();
        this.$successAlert(response.data.message);
      }).catch(error => {
        this.$errorAlert(error);
      });
    },
    openAdvancedSearch() {
      this.showAdvancedSearch = !this.showAdvancedSearch;
    },
    doFilter() {
      this.$refs.table.refresh();
    },
    resetFilter() {
      this.filters.name = null;
      this.filters.supplier_code = null;
      this.filters.email = null;
      this.filters.mobile = null;
      this.filters.phone = null;
      this.filters.country_code = null;
      this.filters.postal_code = null;
      this.filters.address_1 = null;
      this.filters.user_id = null;
      this.filters.status = null;
      this.user = null;
      this.country = null;

      this.$refs.table.refresh();
    },
    getFetch() {
      this.$refs.table.refresh();
    },

    deleteItem(item) {
      this.$confirmAlert('', this.actionDelete, item);
    },
    // getCountries(filter) {
    //     ApiService.get("base/dependency/countries",{params:{filter: filter}}).then((response) => {
    //         this.countries = response.data.data;
    //     });
    // },

    getCountries(filter) {
      if (filter && filter.length >= 3)
        ApiService.get("base/dependency/countries", {params: {filter: filter}}).then((response) => {
          this.countries = response.data.data;
        });
    },
    getUsers(filter) {
      if (filter && filter.length >= 3)
        ApiService.get("base/dependency/users", {params: {filter: filter}}).then((response) => {
          this.users = response.data.data;
        });
    },

    actionDelete(item) {
      ApiService.delete(`${this.mainRoute}/${item.id}`).then((response) => {
        this.getFetch();
        this.$successAlert(response.data.message)
      }).catch((error) => {
        this.$errorAlert(error);
      })
    },

    showModal() {
      this.$refs['open-balance-modal'].show()
    },
    hideModal() {
      this.$refs['open-balance-modal'].hide();
    },
    addOpenBalance(id) {
      this.innerId = id;
      this.showModal();
    },
    getDataAfterSave() {
      this.innerId = null;
      this.doFilter();
    },

    showAccountStatementModal() {
      this.$refs['account-statement-modal'].show()
    },
    hideAccountStatementModal() {
      this.$refs['account-statement-modal'].hide();
    },
    showAccountStatement(id) {
      this.innerId = id;
      this.showAccountStatementModal();
    },
    getAccountStatement(){
      let route = this.$router.resolve({path: '/print/supplier-account-statement/'+this.innerId, query: this.account_statement});
      window.open(route.href, '_blank');
    },
  },
};
</script>
